import React from "react";
import Head from "next/head";
import { jsonLdScriptProps } from "react-schemaorg";
import { Product } from "schema-dts";

import { StrainReview } from "custom-types/Reviews";
import { StrainV2 } from "custom-types/StrainV2";

const StrainPageSchema = ({
  strain,
  reviews,
}: {
  strain: StrainV2;
  reviews: StrainReview[];
}) => {
  const {
    averageRating,
    descriptionPlain,
    name,
    nugImage,
    reviewCount,
    subtitle,
  } = strain;

  return (
    <Head>
      <script
        {...jsonLdScriptProps<Product>({
          "@context": "https://schema.org",
          "@type": "Product",
          ...(subtitle && { alternateName: subtitle }),
          name: name,
          ...(descriptionPlain && { description: descriptionPlain }),
          category: "Cannabis Strain",
          ...(nugImage && { image: nugImage }),
          aggregateRating: {
            "@type": "AggregateRating",
            bestRating: 5,
            ratingValue: averageRating,
            reviewCount: reviewCount,
          },
          review: reviews.map((review) => ({
            "@type": "Review",
            author: {
              "@type": "Person",
              name: review.user.username,
            },
            datePublished: review.created,
            reviewBody: review.text,
            reviewRating: {
              "@type": "Rating",
              bestRating: 5,
              ratingValue: review.rating,
            },
          })),
        })}
      />
    </Head>
  );
};

export default StrainPageSchema;
