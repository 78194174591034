import React, { useMemo, useState } from "react";

import { useStrainPageContext } from "context/StrainPageContext";
import useUserFavorites from "redux/hooks/useUserFavorites";

import FavoriteButton from "components/botanic/FavoriteButton";

type Props = {
  eventTrackingProps?: Record<string, string | number | undefined>;
};

const FavoriteStrainButton = ({ eventTrackingProps }: Props) => {
  const {
    strain: { slug, id, totalFollowers = 0 },
  } = useStrainPageContext();

  const [followers, setFollowers] = useState(totalFollowers);

  const {
    favoritedStrains,
    favoriteStrain: onFavoriteClick,
    checkIfUserFollows,
  } = useUserFavorites();

  const userFollows = useMemo(
    () => checkIfUserFollows(slug),
    [favoritedStrains?.strains],
  );
  const onButtonClick = (
    userFollows: boolean,
    id: number,
    setFollowers: (count: number) => void,
  ) => {
    onFavoriteClick({
      doesUserFollow: userFollows,
      strainId: id,
      ...eventTrackingProps,
    }).then((response) => {
      if (response === "followed") {
        setFollowers(followers + 1);
      } else if (response === "unfollowed") {
        setFollowers(Math.max(followers - 1, 0));
      }
    });
  };

  return slug && id ? (
    <FavoriteButton
      aria-label="Favorite strain"
      data-testid="favorite-strain-button"
      onClick={() => onButtonClick(userFollows, id, setFollowers)}
      isFavorited={userFollows}
      label={followers.toLocaleString()}
    />
  ) : (
    <></>
  );
};

export default FavoriteStrainButton;
