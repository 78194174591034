import React, { memo } from "react";

import { StrainV2 } from "custom-types/StrainV2";

import MetaTags from "components/MetaTags";

import { generatedDefaultDescription } from "./GeneratedStrainDescription";

const StrainPageMetadata = ({ strain }: { strain: StrainV2 }) => {
  const {
    descriptionPlain,
    name,
    subtitle,
    nugImage,
    flowerImagePng,
    cannabinoids,
  } = strain;

  const title = subtitle
    ? `${name} ${subtitle} Weed Strain Information | Leafly`
    : `${name} Weed Strain Information | Leafly`;

  const metaDescription = () => {
    if (descriptionPlain) {
      return `${descriptionPlain.substring(0, 300)}...`;
    } else {
      return generatedDefaultDescription(strain, false) as string;
    }
  };

  const ogImage = () => {
    if (nugImage) {
      return `${nugImage}?auto=compress&w=1200&h=630&fit=crop&bg=FFFFFF&fit=fill`;
    } else if (
      // has major cannabinoids?
      (cannabinoids?.thc?.percentile50 || 0) >= 1 ||
      (cannabinoids?.cbd?.percentile50 || 0) >= 1
    ) {
      return `${flowerImagePng}?auto=compress&w=1200&h=630&fit=crop&bg=FFFFFF&fit=fill`;
    } else {
      return "https://leafly-public.imgix.net/leafly-logo-social.png?auto=compress&w=1200&h=630";
    }
  };

  return (
    <MetaTags title={title} description={metaDescription()} image={ogImage()} />
  );
};

export default memo(StrainPageMetadata);
