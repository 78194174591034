import React, { useEffect } from "react";
import classNames from "classnames";
import dynamic from "next/dynamic";
import Link from "next/link";
import { useRouter } from "next/router";
import { useSelector } from "react-redux";

import getStrain from "api/requests/getStrain";
import getStrainReviews from "api/requests/getStrainReviews";
import { DISPENSARY_DEFAULT_MAX_DISTANCE } from "constants/dispensary";
import { Action, Category } from "constants/events";
import { AddToBagProvider } from "context/AddToBagContext";
import { StrainPageProvider } from "context/StrainPageContext";
import { StrainReview } from "custom-types/Reviews";
import { StrainV2 } from "custom-types/StrainV2";
import { useEventTracker } from "hooks/useEventTracker";
import useStrainFeature from "hooks/useStrainFeature";
import useUserFavorites from "redux/hooks/useUserFavorites";
import { getCoordinates } from "redux/selectors/location";
import { getUserId } from "redux/selectors/user";
import { createGetServerSideProps } from "utils/createGetServerSideProps";
import getStrainRedirect from "utils/getStrainRedirect";
import { fetchNearbyDispensaries } from "utils/strains/fetchNearbyDispensaries";

import Classification from "components/botanic/Classification";
import Breadcrumb from "components/Breadcrumb";
import Breadcrumbs from "components/Breadcrumbs";
import ComparisonShoppingSection from "components/ComparisonShoppingSection";
import GoogleAd from "components/GoogleAd";
import Divider from "components/StrainPage/Divider";
import FavoriteModal from "components/StrainPage/FavoriteModal";
import FavoriteButton from "components/StrainPage/FavoriteStrainButton";
import HeroImage from "components/StrainPage/HeroImage";
import ReviewCTA from "components/StrainPage/ReviewCTA";
import Section from "components/StrainPage/Section";
import ShopStrainButton from "components/StrainPage/ShopStrainButton";
import StrainCannabinoids from "components/StrainPage/StrainCannabinoids";
import StrainDescription from "components/StrainPage/StrainDescription";
import StrainDispensariesSection from "components/StrainPage/StrainDispensariesSection";
import StrainHighlights from "components/StrainPage/StrainHighlights";
import StrainPageMetadata from "components/StrainPage/StrainPageMetadata";
import StrainPageSchema from "components/StrainPage/StrainPageSchema";
import StrainPhotos from "components/StrainPage/StrainPhotos";
import StrainReviews from "components/StrainPage/StrainReviews";
import StrainSensations from "components/StrainPage/StrainSensations";
import StrainQuizCTAPlaceholder from "components/StrainQuiz/StrainQuizCTAPlaceholder";
import StrainTerpenes from "components/StrainTerpenes";
import Video from "components/Video";

const SOTYBannerContainer = dynamic(
  () => import("components/StrainPage/SOTYBanner"),
);
const StrainGrowInfo = dynamic(
  () => import("components/StrainPage/StrainGrowInfo"),
);
const StrainLineage = dynamic(
  () => import("components/StrainPage/StrainLineage"),
);
const StrainQuizContainer = dynamic(
  () => import("components/StrainQuiz/StrainQuizContainer"),
  {
    loading: () => <StrainQuizCTAPlaceholder className="mb-lg mt-sm" />,
  },
);
const StrainFeatureAd = dynamic(
  () => import("components/StrainPage/StrainFeatureAd"),
);
const StrainComparison = dynamic(() => import("components/StrainComparison"));

type StrainPageProps = {
  strain: StrainV2;
  reviews: StrainReview[];
};

const StrainPage: React.FC<StrainPageProps> = ({ strain, reviews }) => {
  const {
    award,
    celebrationColors,
    name,
    subtitle,
    phenotype,
    slug,
    parents,
    children,
    growInfo,
    id,
  } = strain;
  const { useInitiateFavorites } = useUserFavorites();
  const coordinates = useSelector(getCoordinates);
  useInitiateFavorites();
  const { showStrainFeature } = useStrainFeature(coordinates, strain);

  const hasLineage = parents?.length !== 0 || children?.length !== 0;

  const hasGrowInfo =
    growInfo?.averageYield &&
    growInfo?.difficulty &&
    (growInfo?.floweringDays || 0) > 0 &&
    growInfo?.height;

  const hasGrowNotes = !!growInfo?.growNotesPlain;

  const hasPhotos = Boolean(strain?.photoCount && strain.photoCount > 0);

  const userId = useSelector(getUserId);

  const router = useRouter();
  const returnToStrainPage = () => router.push(`/strains/${strain.slug}`);
  const showFavoriteModal = router.query.show_favorites_modal;
  const favoritedRedirect = () =>
    router.push(`/strains/${strain.slug}?strainId=${strain.id}`);
  const [hasNearbyDispensaries, setHasNearbyDispensaries] =
    React.useState(false);

  const { publishEvent } = useEventTracker();

  const strainId = id;

  useEffect(() => {
    fetchNearbyDispensaries(
      strain,
      coordinates,
      DISPENSARY_DEFAULT_MAX_DISTANCE,
    ).then((results) => {
      setHasNearbyDispensaries(results.dispensaries.length > 0);
    });
  }, [coordinates]);

  const onPhenotypeClick = () => {
    publishEvent({
      action: Action.click,
      category: Category.strainType,
      label: `${phenotype}`,
      strainId: strainId,
      userId: userId,
    });
  };

  return (
    <StrainPageProvider strain={strain} eventPage="Strain page">
      <AddToBagProvider>
        <StrainPageMetadata strain={strain} />
        {strain?.reviewCount !== 0 && (
          <StrainPageSchema strain={strain} reviews={reviews} />
        )}
        <div>
          <section className="container mt-xxl">
            <SOTYBannerContainer
              celebrationColors={celebrationColors}
              award={award}
            />
            <div className="w-full flex flex-col lg:w-5/6 lg:flex-row mx-auto">
              <section className="relative flex items-center">
                <div className="w-3/5">
                  <HeroImage />
                  <StrainQuizContainer showResults={true} />
                </div>
                <div className="w-2/5 ml-sm">
                  <div className="flex justify-between mt-sm items-center">
                    <ReviewCTA showRedesign={true} />
                  </div>
                  <div className="flex justify-between">
                    <div className="mt-lg">
                      <FavoriteButton />
                    </div>
                  </div>
                </div>
              </section>
              <div
                className={classNames(
                  "w-full mt-lg lg:flex lg:flex-col lg:justify-center",
                  {
                    "lg:-mt-20": !hasNearbyDispensaries,
                    "lg:-mt-4": hasNearbyDispensaries,
                  },
                )}
              >
                <div className="flex items-center mb-xs">
                  {phenotype && (
                    <Link
                      href={`/strains/lists/category/${phenotype.toLowerCase()}`}
                      onClick={onPhenotypeClick}
                    >
                      <Classification variant="phenotypeRedesign">
                        {phenotype}
                      </Classification>
                    </Link>
                  )}
                  <StrainCannabinoids />
                </div>
                <h1 className="heading--l mb-xs">{name}</h1>
                {subtitle && (
                  <h2 className="text-xs font-normal truncate text-secondary">
                    {subtitle}
                  </h2>
                )}
                {hasNearbyDispensaries && <ShopStrainButton strain={strain} />}
              </div>
            </div>
            <section className="flex justify-center">
              <div className="w-full lg:w-1/3 mt-[20px]">
                <StrainHighlights />
              </div>
              <div className="lg:w-1/2 lg:ml-lg">
                <StrainDescription className="hidden lg:block" />
              </div>
            </section>
            <Divider />
            <StrainDescription className="mt-xl lg:hidden" />
            {showStrainFeature !== undefined && !showStrainFeature && (
              <div
                data-testid="google-ad"
                className="flex justify-center mt-xxl lg:mb-xxl gam-rectangular-fixed-height"
              >
                <GoogleAd
                  adPath="/13212770/strain/sponsorship"
                  targeting={{
                    section: "strain",
                    strain: slug,
                    zone: "abvf",
                  }}
                  sizes={{ desktop: [728, 90], mobile: [320, 50] }}
                />
              </div>
            )}
            {showStrainFeature !== undefined && showStrainFeature && (
              <StrainFeatureAd strain={strain} />
            )}
          </section>
          <section id="strain-shopping">
            <StrainDispensariesSection />
            <StrainSensations className="container">
              <StrainQuizContainer showResults={false} />
            </StrainSensations>
            <StrainTerpenes />
            <ComparisonShoppingSection />
            <Divider />
            <StrainComparison />
            <Divider />
            {slug === "do-si-dos" ? (
              <div className="container mt-xxl">
                <div className="shadow-low rounded overflow-hidden">
                  <GoogleAd
                    adPath="/13212770/home/half_takeover"
                    targeting={{
                      section: "strain",
                      strain: slug,
                    }}
                  />
                </div>
              </div>
            ) : (
              <div className="flex justify-center mt-xxl gam-square-fixed-height">
                <GoogleAd
                  adPath="/13212770/strain/medrec_upper"
                  sizes={{ desktop: [300, 250], mobile: [300, 250] }}
                  targeting={{
                    section: "strain",
                    strain: slug,
                    zone: "abvf",
                  }}
                />
              </div>
            )}
            <Divider />
            <StrainReviews reviews={reviews} hideMetadata={true} />
            <Divider />
            <Section
              title="Strain spotlight"
              id="strain-spotlight-section"
              className="container"
            >
              <Video
                playerId="strain-page-video-player"
                playerScript="https://video.leafly.com/libraries/MCKAFIhB.js"
                playlist="https://video.leafly.com/v2/playlists/WL4FtIpb?search=__CONTEXTUAL__"
              />
            </Section>
            {(hasLineage || hasGrowInfo || hasGrowNotes) && (
              <div id="strain-lineage-and-growth-container">
                <Divider />
                <div className="container flex flex-col md:flex-row">
                  {hasLineage && <StrainLineage />}
                  {(hasGrowInfo || hasGrowNotes) && <StrainGrowInfo />}
                </div>
              </div>
            )}
            {hasPhotos && (
              <div id="strain-photos-container">
                <Divider />
                <StrainPhotos className="pt-xxl container" />
              </div>
            )}
            <Divider />
            <div className="flex justify-center mt-xxl gam-square-fixed-height mb-lg">
              <GoogleAd
                adPath="/13212770/strain/medrec_lower"
                sizes={{ desktop: [300, 250], mobile: [300, 250] }}
                targeting={{
                  section: "strain",
                  strain: slug,
                  zone: "abvf",
                }}
              />
            </div>
            <div className="container">
              <Breadcrumbs>
                <Breadcrumb href="/strains">Weed Strains</Breadcrumb>
                {!!phenotype && (
                  <Breadcrumb
                    href={`/strains/lists/category/${phenotype.toLowerCase()}`}
                  >
                    {phenotype}
                  </Breadcrumb>
                )}
                <Breadcrumb href={`/strains/${slug}`} currentLocation={true}>
                  {name}
                </Breadcrumb>
              </Breadcrumbs>
            </div>
          </section>
        </div>
        {showFavoriteModal && (
          <FavoriteModal
            aria-labelledby="add-strain-to-favorites"
            closeModal={returnToStrainPage}
            category="Reviews"
            favoritedRedirect={favoritedRedirect}
          />
        )}
      </AddToBagProvider>
    </StrainPageProvider>
  );
};

export const getServerSideProps = createGetServerSideProps<StrainPageProps>(
  async (context) => {
    const { query } = context;
    const slug = Array.isArray(query?.strainSlug)
      ? query.strainSlug[0]
      : query.strainSlug || "";

    const redirect = getStrainRedirect(slug, `/strains/${slug}`);

    if (redirect) {
      return {
        redirect,
      };
    }

    const strain = await getStrain(slug);

    if (!strain) {
      return {
        notFound: true,
      };
    }

    const { data: reviews } = await getStrainReviews(slug, 3);

    return {
      props: {
        reviews,
        strain,
      },
    };
  },
);

export default StrainPage;
